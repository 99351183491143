<template>
    <div class="home-container">
        <el-card shadow="always" style="margin-bottom:30px;">
            <div class="emq-title">
                网络配置
            </div>
            <el-form ref="configForm" hide-required-asterisk size="small" label-position="top" :model="connection">
                <el-row :gutter="20">
                    <el-col :span="6">
                        <el-form-item prop="ssid" label="WIFI">
                            <el-row :gutter="10">
                                <el-col :span="12">
                                    <el-input v-model="connection.ssid"></el-input>
                                </el-col>
                                <el-col :span="12">
                                    <el-input v-model="connection.password" show-password></el-input>
                                </el-col>
                            </el-row>
                        </el-form-item>
                    </el-col>
                    <el-col :span="6">
                        <el-form-item prop="port" label="MQTT">
                            <el-input v-model="connection.mqtt_broker"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="6">
                        <el-form-item prop="port" label="话题">
                            <el-input v-model="connection.mqtt_topic"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="3">
                        <el-form-item prop="port" label="设备">
                            <el-button type="primary" @click="connectPort">选择串口</el-button>
                        </el-form-item>
                    </el-col>
                    <el-col :span="3">
                        <el-form-item prop="port" label="下载配置">
                            <el-button type="primary" @click='downloadESP32'>
                                下载
                            </el-button>
                        </el-form-item>
                    </el-col>

                </el-row>
            </el-form>
        </el-card>
    </div>


</template>

<script>



export default {

    name: 'Serial',

    data() {
        return {
            connection: {
                ssid: 'fitsir',
                password: 'songyuan',
                mqtt_broker: '120.46.165.147',
                mqtt_topic: 'robot/button',
                mqtt_client_username: 'robot',
                mqtt_client_password: 'robot',
            },

            serial: {
                port: null,
                reader: null,
                writer: null,
                baudrate: 115200,
            },
        }
    },
    created() {

    },
    mounted() {

    },
    methods: {

        // 连接串口
        async connectPort() {
            try {
                // 如果未选择串口，或者需要重新选择串口
                if (!this.serial.port) {
                    this.serial.port = await navigator.serial.requestPort();
                }

                // 检查串口是否已打开
                if (this.serial.port.readable || this.serial.port.writable) {
                    console.log('串口已连接');
                    this.$message.success('串口已连接');
                } else {
                    // 尝试打开串口，并捕获可能的错误
                    try {
                        await this.serial.port.open({ baudRate: this.serial.baudrate });
                        console.log('串口已成功连接');
                    } catch (openError) {
                        console.error('无法打开串口:', openError);
                        this.$message.error('无法打开串口，请检查设备是否已被占用');
                        return;
                    }

                    // 检查串口的 readable 和 writable 属性
                    if (this.serial.port.readable && this.serial.port.writable) {
                        console.log('串口读写流已初始化');
                        this.$message.success('串口已成功连接并准备好通信');
                    } else {
                        console.error('串口读写流未初始化');
                        this.$message.error('串口已连接，但无法通信');
                    }
                }
            } catch (error) {
                if (error.name === 'NotFoundError') {
                    console.error('未选择任何串口设备。');
                    this.$message.error('未选择任何串口设备，请重试。');
                } else if (error.name === 'InvalidStateError') {
                    console.error('串口已打开:', error);
                    this.$message.error('串口已打开，无需重复连接。');
                } else {
                    console.error('无法连接到串口设备:', error);
                    this.$message.error('无法连接到串口设备，请检查连接并重试。');
                }
            }
        },

        // 发送消息
        async downloadESP32() {
            console.log('串口对象:', this.serial.port);
            console.log('串口可写流:', this.serial.port ? this.serial.port.writable : null);

            if (this.serial.port && this.serial.port.writable) {
                try {
                    const writer = this.serial.port.writable.getWriter();
                    console.log(this.connection.ssid)
                    console.log(this.connection.password)
                    console.log(this.connection.mqtt_broker)
                    console.log(this.connection.mqtt_topic)
                    await writer.write(new TextEncoder().encode(`ssid:${this.connection.ssid}\r\n`));
                    await writer.write(new TextEncoder().encode(`password:${this.connection.password}\r\n`));
                    await writer.write(new TextEncoder().encode(`mqtt_broker:${this.connection.mqtt_broker}\r\n`));
                    await writer.write(new TextEncoder().encode(`mqtt_topic:${this.connection.mqtt_topic}\r\n`));
                    writer.releaseLock();
                    console.log('配置已发送');
                    this.$message.success('配置已成功发送到设备');
                } catch (error) {
                    console.error('发送配置时出错:', error);
                    this.$message.error('发送配置时出错，请重试。');
                }
            } else {
                console.log('请先连接串口');
                this.$message.error('请先连接串口设备');
            }
        }



    },
}
</script>

<style lang="scss">
@import url('../assets/style/home.scss');

.home-container {
    max-width: 1100px;
    margin: 0 auto;

    .conn-btn {
        color: #fff;
        background-color: #00b173;
        font-size: 14px;
    }

    .export-btn {
        margin-top: 20px;
        margin-bottom: 20px;
        float: right;
        width: 100px;
    }

    .class-btn {
        margin-top: 20px;
        margin-bottom: 20px;
        // float: right;
        width: 100px;
    }


    .publish-btn {
        width: 100px;
        margin-bottom: 20px;
        float: right;
    }

    .el-button--success {
        background-color: #34c388 !important;
        border-color: #34c388 !important;
        font-size: 14px !important;
    }

    .el-button--danger {
        background-color: #f5222d !important;
        border-color: #f5222d !important;
    }

    .el-form-item {
        &.is-error {

            .el-input__inner,
            .el-textarea__inner {
                box-shadow: 0 0 0 2px rgba(245, 34, 45, 0.2);
            }
        }

        &.is-success {

            .el-input__inner,
            .el-textarea__inner {
                border-color: #34c388 !important;
            }
        }
    }
}
</style>
